*,
::after,
::before {
  box-sizing: border-box;
}

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Cabin&family=Roboto+Condensed:wght@300;400;700&display=swap");

html {
  font-size: 102%;
} /*16px*/

:root {
  /* colors */
  --primary-50: #92d2bf;
  --primary-100: #7cc8b2;
  --primary-200: #66bfa5;
  --primary-300: #50b699;
  --primary-400: #3aad8c;
  --primary-500: #24a47f;
  --primary-600: #209472;
  --primary-700: #1d8366;
  --primary-800: #197359;
  --primary-900: #16624c;

  /* grey */
  --grey-50: #f0f4f8;
  --grey-100: #d9e2ec;
  --grey-200: #bcccdc;
  --grey-300: #9fb3c8;
  --grey-400: #829ab1;
  --grey-500: #627d98;
  --grey-600: #486581;
  --grey-700: #334e68;
  --grey-800: #243b53;
  --grey-900: #102a43;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */
  --headingFont: "Roboto Condensed", Sans-Serif;
  --bodyFont: "Cabin", Sans-Serif;
  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 500px;
  --fluid-width: 90vw;
  --breakpoint-lg: 992px;
  --nav-height: 6rem;
  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
  /* background: var(--backgroundColor); */
  background-color: var(--backgroundColor);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
  letter-spacing: var(--letterSpacing);
}
a,
button {
  line-height: 1.15;
}

button:disabled {
  cursor: not-allowed;
}
ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
  overflow: visible;
}
/* buttons */

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}
.btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}
.btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}
.btn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-700);
}
.btn-block {
  width: 100%;
}
.btn-hero {
  font-size: 1.25rem;
  padding: 0.5rem 1.25rem;
}
.btn-danger {
  background: var(--red-light);
  color: var(--red-dark);
}
.btn-danger:hover {
  background: var(--red-dark);
  color: var(--white);
}
/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
  text-align: center;
  letter-spacing: var(--letterSpacing);
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */

.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem auto;
  transition: var(--transition);
}
.form:hover {
  box-shadow: var(--shadow-4);
}
.form-label {
  display: block;
  font-size: var(--smallText);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea,
.form-select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
}
.form-input,
.form-select,
.btn-block {
  height: 35px;
}
.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 15rem;
}

::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 2s linear infinite;
}
.loading-center {
  margin: 0 auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}

.container {
  width: var(--fluid-width);
  max-width: var(--max-width);
  margin: 0 auto;
}
.full-page {
  min-height: 100vh;
}

.coffee-info {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1rem;
  letter-spacing: var(--letterSpacing);
}
.coffee-info span {
  display: block;
}
.coffee-info a {
  color: var(--primary-500);
}

@media screen and (min-width: 992px) {
  .coffee-info {
    text-align: left;
  }
  .coffee-info span {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.story-icon {
  font-size: 1.5rem;
}

.front-nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: var(--max-width);
  justify-content: space-between;
}

.front-nav-link {
  color: var(--black);
  text-transform: capitalize;
}

/* Sponsors Footer */
#rcorners1 {
  border-radius: 20px;
  /* padding:2%;  */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  min-width: 0%;
  min-height: 10em;
}
/* .sponsorsFooter {
  width: 100%;
  height: auto;
  padding: 40px 0 10px;
  z-index: 14;
  margin-top: 0px;
  z-index: 1 !important;
  text-align: center !important;
  z-index: 99;
}


.sponsorsFooter img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: auto;
  vertical-align: middle;
  max-width: 120px;
}



.sponsorsFooter ul li {
  margin: 35px 16px 0px;
  display: inline-block;
}
 */
.row_own {
  /*background-color:red;*/
  display: grid;
  /*grid-auto-flow: column;*/
  grid-template-columns: auto auto auto;
  width: 100%;
  margin: 0 auto;
  gap: 2vw;
}

.row_own2 {
  /*background-color:red;*/
  display: grid;
  /*grid-auto-flow: column;*/
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  column-gap: 2vw;
  align-items: center;
}

.stat_box_own {
  background-color: #ffffff;
  text-align: center;
  height: 100;
  border-radius: 0.5rem;
  box-shadow: 0 2.5px 5px #dcdcdc;
  padding: 1rem;
  margin-top: 5vh;
}

.stat_box_own2 {
  background-color: #ffffff;
  text-align: center;
  height: 100;
  border-radius: 0.5rem;
  box-shadow: 0 2.5px 5px #dcdcdc;
  padding: 1rem;
  margin-top: 5vh;
  max-width: 25rem;
}

.stat_box_own3 {
  background-color: #ffffff;
  text-align: center;
  height: 100;
  border-radius: 0.5rem;
  box-shadow: 0 2.5px 5px #dcdcdc;
  padding: 1rem;
  margin-top: 5vh;
}

.stat_box_own img {
  max-width: 8rem;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.stat_box_own2 img {
  max-width: 8rem;
  object-fit: contain;
  width: 100%;
  height: 130px;
  transform: translateY(2px);
}

.stat_box_own3 img {
  max-width: 8rem;
  object-fit: contain;
  height: 130px;
  width: 100%;
}

.row_own_1 {
  /*background-color:red;*/
  display: grid;
  /*grid-auto-flow: column;*/
  grid-template-columns: auto auto auto auto auto auto auto;
  width: 100%;
  margin: 0 auto;
  gap: 2vw;
}

.container-fluid {
  justify-self: center;
  margin-top: 0rem;
  border-top: 2.5px solid var(--grey-100);
  border-bottom: 2.5px solid var(--grey-100);
  padding-top: 4rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 768px) {
  .container-fluid {
    padding-bottom: 10rem;
  }
}

.special-a {
  display: block;
  min-width: 100%;
  min-height: 100%;
}

@media screen and (max-width: 1060px) {
  #primary {
    width: 67%;
  }

  #secondary {
    width: 30%;
    margin-left: 3%;
  }

  .row_own,
  .row_own2 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  .stat_box_own,
  .stat_box_own3 {
    margin: 1rem;
  }

  .row_own_1 {
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
  }
}

.modal {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: var(--borderRadius);
  width: 400px;
}

.modal-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  align-self: flex-end;
  margin-top: 0.5rem;
  align-items: center;
}

.modal-btn-container button {
  height: 35px;
}

.modal-clear-btn {
  background: var(--grey-500);
}
.modal-clear-btn:hover {
  background: var(--black);
}

.close {
  position: absolute;
  top: -0.75rem;
  right: -0.65rem;
  opacity: 0.7;
  font-size: 1rem;
  cursor: pointer;
  padding: 0rem;
  margin: 0;
  display: grid;
  width: 1.75rem;
  height: 1.75rem;
  align-content: center;
  border-radius: 1.75rem;
  background-color: #24a47f;
  border-color: transparent;
  color: #ffffff;
}

.green {
  color: var(--primary-500);
}

.resources-page {
  color: var(--primary-500);
  letter-spacing: normal;
  text-decoration: underline;
  transition: var(--transition);
}

.resources-page:hover {
  color: var(--primary-700);
}

/*
===============
Team
===============
*/
.team-title {
  margin: 0rem auto;
  padding: 1rem 0 3rem 0;
  text-align: center;
}

.blog {
  background-color: var(--backgroundColor);
}

.card {
  height: 21rem;
  position: relative;
  -webkit-perspective: 1500px;
  perspective: 1500px;
}

.card-side {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 3s ease-in-out;
  transition: all 0.5s ease-in;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  -webkit-transform: translate3d(0, 0, 0);
  visibility: visible;
}

.card-front {
  background-color: var(--white);
  display: grid;
  place-items: center;
  grid-template-rows: 2fr 1fr;
}

.card-title {
  font-size: bolder;
  display: block;
  align-self: start;
  margin-top: -2rem;
  text-align: center;
  font-weight: 700;
}

.card-back {
  background-color: var(--white);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  display: grid;
  place-items: center;
}

.card:hover .card-front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.card:hover .card-back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

.card-photo-container {
  height: 10rem;
  width: 10rem;
  overflow: hidden;
  border-radius: 10rem;
}

.card-photo {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  object-fit: cover;
}

.blog-center {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  column-gap: 1.5rem;
  row-gap: 1rem;
}

@media screen and (min-width: 65rem) {
  .blog-center {
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  }
}

.section {
  padding: 5rem 0;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

.role {
  font-weight: 700;
  text-align: center;
}

.card-icons {
  max-width: max-content;
  margin: -0.75rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
}

.card-icon {
  color: var(--primary-500);
  font-size: 1.25rem;
}

.card-icon:hover {
  color: var(--primary-700);
}

.card-icons-alt {
  grid-template-columns: 1fr;
}

/* profile */

.icon {
  padding: 0;
  margin: 0;
}

.story-icon {
  display: grid;
  place-items: center;
  height: 2.5rem;
  width: 2.5rem;
  border: #24a47f solid 2.5px;
  border-radius: 50%;
}

.icon-profile {
  width: 16rem;
  height: 16rem;
}

.profile-center {
  display: grid;
  grid-template-columns: 18rem 2fr;
}

.span-icon {
  font-size: 10rem;
}

.profile-form-center {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.profile-form-center button {
  margin-top: 1rem;
}

@media screen and (max-width: 780px) {
  .profile-center {
    grid-template-columns: 1fr;
  }

  .icon-profile {
    width: 12rem;
    height: 12rem;
    display: block;
    margin: 0rem auto 1rem;
    text-align: center;
  }

  .span-icon {
    font-size: 8rem;
  }
}

.tab-icon {
  display: none;
}
.content-long {
  display: block;
}
.content-short {
  display: none;
}

@media screen and (max-width: 600px) {
  .tab-icon {
    display: block;
  }

  .tab-type {
    display: none;
  }
  .content-long {
    display: none;
  }
  .content-short {
    display: block;
  }
}

/* .welcome-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 30%;
  padding: 5px;
}
.welcome-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.welcome-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
} */

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #afaeae;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #727070;
}

/* Direct Message Styles */

/* @media (min-width: 1000px) { */
.messages-div {
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
}
.chat-panel {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: white;
  padding: 20px;
  border-radius: 0.25rem;
  max-width: 350px;
  max-height: 90vh;
  height: 650px;
  min-height: 400px;
  align-items: center;
  margin: 5px;
  box-shadow: var(--shadow-2);
}
.chat-row {
  border-radius: 9px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  background-color: white;
  border-width: 0;
  cursor: pointer;
}
.chatbot-row {
  border-radius: 9px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  background-color: white;
  border-width: 0;
  cursor: pointer;
  margin-top: auto;
}
.chatbot-row:hover {
  background-color: var(--grey-50);
}
.chat-row.chat-selected {
  background-color: var(--grey-100);
}
.chat-row:hover {
  background-color: var(--grey-50);
}
.chat-list {
  padding: 5px;
  /* max-height: 500px; */
  margin-bottom: 15px;
  overflow: auto;
  width: 100%;
}
.chat-collapse {
  height: 50px;
  width: 50px;
  display: none !important;
  min-width: 0px !important;
}
.chat-icon {
  margin: 5px;
}
.chat-info {
  margin: 5px;
}
.chat-title-info {
  margin: 5px;
}
.chatbot-nav-info {
  margin: 10px;
  color: black;
}

.chat-name {
  margin: 1px;
  text-align: left;
}
.chat-status {
  margin: 1px;
  text-align: left;
}
.main-chat-div {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 700px;
  height: 660px;
}
.chat-title {
  border-radius: 5px;
  display: none;
  box-shadow: var(--shadow-2);
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  background-color: white;
  border-width: 0;
  margin: 5px;
}
.content {
  height: 100%;
}
.chatbot-nav-outer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-top: 100px;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  border-radius: 9px;
}
.chatbot-nav-outer:hover {
  background-color: var(--grey-50);
}

.chatbot-nav-inner {
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  /* margin-top: 5px;
  margin-bottom: 5px; */
}
.chatbot-nav:hover {
  background-color: var(--grey-50);
}

@media screen and (max-width: 992px) {
  .chat-nav-inner {
    padding: 0;
  }

  .chatbot-nav-outer {
    width: 100%;
  }
}
.message-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding-top: 1rem;
  /* padding-bottom: 1rem; */
  border-radius: 0.25rem;
  height: 655px;
  margin: 5px;
  box-shadow: var(--shadow-2);
}
.message-list {
  padding: 5px;
  max-height: 565px;
  width: 100%;
}
.message-list > .rce-mlist > .rce-container-mbox > .rce-mbox {
  max-width: 50%;
}
.message-list > .rce-mlist {
  scroll-behavior: smooth;
}
.rce-mbox-text:after {
  content: "\00a0\00a0\00a0\00a0\00a0" !important;
}
.input-div {
  display: flex;
  margin: 1rem;
  margin-top: auto;
  max-height: 50px;
  align-items: center;
  border-radius: 50rem;
  border: 1px solid var(--grey-200);
}

.message-input {
  margin: 2px;
  background-color: transparent !important;
  min-width: 0% !important;
  max-height: 40px;
  flex: 95%;
}

.message-button {
  border: none;
  background-color: transparent;
  margin-right: 10px;
  cursor: pointer;
}

.message-icon {
  color: #102a43;
  font-size: 1.2rem;
  transition: var(--transition);
}

.message-icon:hover {
  color: var(--primary-500);
}

@media screen and (max-width: 700px) {
  .chat-info {
    display: none;
  }

  .chat-panel {
    display: none;
  }

  .chat-panel-opened {
    display: flex;
    width: 100% !important;
  }

  .chat-panel-opened > .chat-list > .chat-row > .chat-info {
    display: block !important;
  }

  .chatbot-row > .chat-info {
    display: block !important;
  }

  .chat-collapse {
    display: block !important;
  }

  .chat-title {
    display: flex !important;
  }   b

  .message-list {
    max-height: 500px;
  }

  .main-chat-div {
    width: 90%;
  }

  .message-list > .rce-mlist > .rce-container-mbox > .rce-mbox {
    max-width: 50%;
    min-width: 0%;
  }
  .message-list > .rce-mlist > .rce-container-mbox {
    min-width: 0%;
  }

  .message-panel {
    width: 100%;
  }
}

.greeting-div {
  height: 100%;
  padding: 2rem;
  text-align: center;
  display: grid;
  place-items: center;
}

.greeting-alt {
  text-align: center;
}

.greeting {
  margin: 0 auto;
}

.greeting-icon {
  font-size: 7rem;
}

/*

CONFLICTING STYLES PLEASE RESOLVE



.chat-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.chat-history {
  margin-bottom: 20px;
}

.message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.user-message,
.bot-message {
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  max-width: 70%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.user-message {
  background-color: #e4f3ff;
  align-self: flex-end;
  color: #000;
  margin-left: auto;
  margin-right: 0;
}

.bot-message {
  background-color: #f0f0f0;
  align-self: flex-start;
  color: #000;
  margin-left: 0;
  margin-right: auto;
}

.input-container {
  display: flex;
  align-items: center;
}

input[type='text'] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:focus {
  outline: none;
}


*/
